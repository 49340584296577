.twoColumnWrap {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
  
.twoColumnAuto {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: auto 1fr;
}

.twoColumnAutoRight {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr auto;
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  align-items: center;
}

.noGap {
  column-gap: 0;
}

.smallGap {
  column-gap: 0.25rem;
}

.twoColumn {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  text-align: left;
  &.lineitem {
    grid-template-columns: 15rem 1fr;
  }
  &.lineitemSmall {
    grid-template-columns: 5rem 1fr;
  }
  &.lineitemLarge {
    grid-template-columns: 20rem 1fr;
  }
}

@media (max-width: 767.98px) {
  .twoColumn, .twoColumnWrap, .twoColumnAutoRight {
    grid-template-columns: 1fr;
    &.lineitem, &.lineitemSmall, &.lineitemLarge {
      grid-template-columns: 10rem 1fr;
    }
  }
}