@import '../../../../styles/variables.scss';

.container {
  display: block;

  border-radius: 1rem;
  padding: 1rem 2rem;
  background-color: $background-2;
  margin-top: 2rem;
}

.list {
  position: relative;
  display: block;
  text-align: left;
  transition: all 1s ease-in;

  .content {
    position: relative;
    z-index: 1;
  }

  .title {
    font-size: 2rem;
    font-weight: 100;
    margin-left: -0.25rem;
  }

  .level {
    font-size: 0.75rem;
    font-weight: bold;
    display: inline-block;
    background-color: rgba(255, 255, 255, .1);
    border-radius: .125rem;
    padding: .15rem .25rem;
    margin-left: 0.5rem;
    margin-top: -.25rem;

    &.sub {
      background-color: #8205b4;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
    &.vip {
      background-color: #e005b9;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
    &.mod {
      background-color: #00ad03;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
    &.bo {
      background-color: #c41513;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
  }
}
