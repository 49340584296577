.icon {
  font-size: 5rem;
  height: 5rem;
  width: 5rem;
  padding: 0;
  margin-bottom: 0.5rem;
  color: white;
  fill: currentColor;
  opacity: 0.25;
  margin-right: 2rem;
}

.container {
  *[id] {
    position: relative;
    top: -10rem;
  }
}

.iconButton {
  display: inline-grid;
  text-align: center;
  padding: 1.5rem;
  color: white;
  opacity: 0.25;
  border-radius: 999999px;

  svg {
    margin: 0;
    font-size: 3rem;
    height: 3rem;
    width: 3rem;
    padding: 0;
    fill: currentColor;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: hsl(212deg, 100%, 65%);
    opacity: 1;
  }
}

@media (max-width: 767.98px) {
  .icon {
    display: none;
  }
  .iconButton {
    padding: 0.5rem;
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .container {
    *[id] {
      top: -12rem;
    }
  }

  .second {
    margin-top: 2rem;
  }
}