@import '../../styles/variables.scss';

// @keyframes Animation { 
//   0% {opacity:1;}
//   30% {opacity:.95;}
//   40% {opacity:0.9;}
//   60% {opacity:0.95;}
//   80% {opacity:0.97;}
//   100% {opacity:1;}
// }

@keyframes Animation { 
  0% {opacity:1;}
  50% {opacity:0.8;}
  100% {opacity:1;}
}

@mixin Animate {
  animation: Animation 3s ease infinite;
}

.container {
  margin: -3em;
  padding: 3em;
  mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, black 35%, transparent 60%);
}

.screenshot {
  position: relative;
  display: inline-block;
  margin: 2em;
  transition: all 1s ease-in;

  img {
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    padding: 1em;
    transition: all 1s ease-in;

    &.blur {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.fullGlow {
    &::before,
    image.blur {
      filter: blur(2em);
    }
  }

  &.smallGlow {
    &::before,
    image.blur {
      filter: blur(1.5em);
    }
  }

  &.noGlow {
    &::before,
    image.blur {
      display: none;
    }
  }

  .reflectionHolder {
    position: absolute;
    top: 100%;
    left: 0;
    height: 5em;
    overflow: hidden;
    transform: translateY(-2em);
  }

  .reflection {
    transform: rotateX(180deg);
    filter: blur(.3em);
    mask-image: linear-gradient(0, white, transparent 20%);
  }

  &::before {
    content: '';
    background-image: $rainbow;
    background-size: 100%;
    position: absolute;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    z-index: 0;
    @include Animate;
  }

  .imageContainer {
    opacity: 0;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;

    &.sizer {
      position: relative;
      opacity: 1;
    }

    &.previous {
      opacity: 0;
      z-index: 1;
    }

    &.current {
      opacity: 1;
      z-index: 2;
    }
  }
}

.reverse {
  .screenshot::before {
    background-image: $rainbow-reverse;
  }
}