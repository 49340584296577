@import '../../../styles/variables.scss';

.container {
  padding: .5em 1em;
  border-radius: .25em;
  display: inline-block;
  letter-spacing: .1em;
  position: relative;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
  }

  &.selected {
    font-weight: bold;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: .5em;
      right: .5em;
      height: .2em;
      // background-image: linear-gradient(120deg, $primary, $twitch-purple 100%);
      background-color: white;
    }
  }

  &:not(.selected):hover {
    background-color: rgba(255, 255, 255, .05);
  }
}