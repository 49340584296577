@import '../../styles/variables.scss';

.container {
  position: sticky;
  top: 0;
  background-color: $background-2;
  padding: 1.5rem 0;
  z-index: 99999;
}

.content {
  text-align: left;
  display: grid;
  grid-template-areas: "Logo Tabs";
  grid-template-columns: 1fr auto;
  align-items: center;

  .logo {
    grid-area: Logo;
  }

  .tabs {
    grid-area: Tabs;
  }
}

.barContainer {
  overflow-x: hidden;
}

.bar, .barBlur, .barBigBlur {
  position: absolute;
  height: 0.25em;
  top: 100%;
  left: 0;
  right: 0;
  background-image: $rainbow;
}

.barBlur, .barBigBlur {
  opacity: .75;
}

.barBlur {
  filter: blur(.25em);
}

.barBigBlur {
  filter: blur(1em);
}

.logo {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  img {
    max-width: 48px;
    margin-right: .5em;
  }

  span {
    margin: 0;
  }
}

@media (max-width: 767.98px) {
  .content {
    grid-template-areas: "Logo" "Tabs";
    row-gap: 0.5rem;
    grid-template-columns: 1fr;
  }
}