@import '../../styles/variables.scss';

.container {
  .header {
    text-align: left;
    padding: 5rem 0;

  .headerContainer {
      position: relative;
      display: grid;
      grid-template-areas: 'Title Buttons' 'Content Content';
      grid-template-columns: 1fr auto;

      .title {
        grid-area: Title;
      }

      .titleButtons {
        position: absolute;
        top: -1rem;
        right: 0;
        grid-area: Buttons;
        white-space: nowrap;
      }

      .headerContent {
        grid-area: Content;
        margin-top: 2rem;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .content {
    text-align: left;
  }

  &.decorated {
    .header, .footer {
      background-color: $background-2;
    }
    .content {
      padding: 3rem 0;
    }
  }

  .decoration {
    width: 100%;
    color: $background-2;
    fill: currentColor;
  
    &.flip {
      transform: scale(-1);
    }
  }
}

@media (max-width: 767.98px) {
  .container {
    .header {
      padding: 2rem 0;
      
      .headerContainer {
        .titleButtons {
           top: 0.25rem;
        }
      }
    }
  }
}