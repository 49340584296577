@import '../../styles/variables.scss';

// @keyframes Animation { 
//   0% {opacity:1;}
//   30% {opacity:.95;}
//   40% {opacity:0.9;}
//   60% {opacity:0.95;}
//   80% {opacity:0.97;}
//   100% {opacity:1;}
// }

@keyframes Animation { 
  0% {opacity:1;}
  50% {opacity:0.8;}
  100% {opacity:1;}
}

@mixin Animate {
  animation: Animation 3s ease infinite;
}

.container {
  position: relative;
  display: inline-block;
}

.bigblur {
  color: transparent;
  background-clip: text;
  background-image: $rainbow;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  filter: blur(2rem);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  @include Animate;
}

.blur {
  color: transparent;
  background-clip: text;
  background-image: $rainbow;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  text-shadow: 0 0 3px rgba(0,0,0,.25), 0 0 2px rgba(0,0,0,.35), 0 0 1px rgba(0,0,0,.75);
  filter: blur(1rem);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  @include Animate;
}

.text {
  color: transparent;
  background-clip: text;
  background-image: $rainbow;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
}

.size {
  color: transparent;
}

.reverse {
  .bigblur {
    background-image: $rainbow-reverse;
  }
  .blur {
    background-image: $rainbow-reverse;
  }
  .text {
    background-image: $rainbow-reverse;
  }
}

.light {
  .bigblur {
    background-image: $rainbow-light;
  }
  .blur {
    background-image: $rainbow-light;
  }
  .text {
    background-image: $rainbow-light;
  }
}

.light.reverse {
  .bigblur {
    background-image: $rainbow-reverse-light;
  }
  .blur {
    background-image: $rainbow-reverse-light;
  }
  .text {
    background-image: $rainbow-reverse-light;
  }
}

@media (max-width: 767.98px) {
  .bigblur {
    filter: blur(1rem);
  }
}