@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
@import './styles/variables.scss';

$body-bg: $background-1;
$body-color: #ffffff;
$font-size-base: 1.25rem;
$headings-font-weight:        700;
$font-family-sans-serif: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 1.75rem
);

$display-line-height: 1.5;

@import '~bootstrap/scss/bootstrap.scss';

em {
  color: $primary-light;
  font-style: normal;
}

body, html, #root {
  min-height: 100vh;
}

@media (max-width: 767.98px) {
  body, html {
    font-size: 12px;
  }
}