@import '../../styles/variables.scss';

.container {
  padding: 4rem 0 2rem;
  margin-top: -1px;

  .content {
    display: grid;
    border-top: 1px dotted rgba(255, 255, 255, .4);
    padding: 1rem 0;
    text-align: left;
    font-size: 1rem;
    grid-template-areas: 'Logo Links' 'Copyright Copyright';
    grid-template-columns: 1fr auto;
    align-items: center;

    .logo {
      display: grid;
      grid-area: Logo;
      grid-template-columns: auto 1fr;
      align-items: center;
    
      img {
        max-width: 32px;
        margin-right: .5em;
      }
    
      span {
        margin: 0;
      }
    }

    .copyright {
      grid-area: Copyright;
      margin-top: 1rem;
      font-size: .8rem;
      font-weight: 500;
    }

    .links {
      grid-area: Links;
      font-size: 1.5rem;

      a {
        border-radius: 999999px;
        display: inline-grid;
        align-items: center;
        justify-items: center;
        height: 3rem;
        width: 3rem;
        color: white;

        &:hover {
          background-color: rgba(255, 255, 255, .1);
          color: $primary;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .container {
    padding: 4rem 1rem 1rem;
  }
}