$primary: hsl(212, 100%, 65%);
$primary-light: hsl(212, 100%, 74%);
$twitch-purple: hsl(261, 43%, 45%);
$twitch-purple-light: hsl(261, 43%, 65%);

$red: hsl(0, 100%, 30%);
$yellow: hsl(44, 93%, 54%);

$background-0: hsl(208, 12%, 7%);
$background-1: hsl(208, 12%, 8%);
$background-2: hsl(208, 12%, 13%);
$background-3: hsl(208, 12%, 30%);
$background-online: hsl(261, 43%, 45%);


$rainbow: linear-gradient(120deg, $primary, $twitch-purple 100%);
$rainbow-reverse: linear-gradient(120deg, $twitch-purple, $primary 100%);

$rainbow-light: linear-gradient(120deg, $primary-light, $twitch-purple-light 100%);
$rainbow-reverse-light: linear-gradient(120deg, $twitch-purple-light, $primary-light 100%);
