@import '../../styles/variables.scss';

// @keyframes Animation { 
//   0% {opacity:1;}
//   30% {opacity:.95;}
//   40% {opacity:0.9;}
//   60% {opacity:0.95;}
//   80% {opacity:0.97;}
//   100% {opacity:1;}
// }

@keyframes Animation { 
  0% {opacity:1;}
  50% {opacity:0.8;}
  100% {opacity:1;}
}

@mixin Animate {
  animation: Animation 3s ease infinite;
}

.container {
  display: inline-block;
  position: relative;
}

.blur {
  color: transparent;
  background-image: $rainbow;
  background-size: 100%;
  filter: blur(10px);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  @include Animate;
}

.border {
  color: transparent;
  background-image: $rainbow;
  background-size: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  @include Animate;
}

.container {
  button {
    border: 0;
    position: relative;
    z-index: 2;
    background: $background-2;
    color: white;
    font-size: 1rem;
    margin: 0;
    padding: .5em 1.5em;
    margin: 3px;
    letter-spacing: .05em;
  }
}

.reverse {
  .blur {
    background-image: $rainbow-reverse;
  }
  .border {
    background-image: $rainbow-reverse;
  }
}

a {
  text-decoration: none;
}