@import '../../styles/variables.scss';

.container {
  ul {
    padding: 0;
    list-style: none;

    li {
      .icon {
        color: $primary;
        margin: 0 0.25rem;
      }
    }
  }
  .more {
    margin-top: 0.5rem;
    margin-left: 2rem;
    font-weight: bold;
  }
}