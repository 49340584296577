@import '../../styles/variables.scss';

.container {
  &:not(:first-child) {
    margin-top: 3rem;
  }

  &:nth-child(2n) {
    background-color: $background-0;
    // margin-top: 1.5rem;
    // margin-bottom: -1.5rem;
    padding: 3rem 0;
  }

  p {
    margin-top: 1rem;
  }

  .icon {
    font-size: 5rem;
    height: 5rem;
    width: 5rem;
    padding: 0;
    margin-bottom: 0.5rem;
    color: white;
    fill: currentColor;
    opacity: 0.25;
    margin-right: 2rem;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .subsection {
    margin-top: 2rem;
  }

  ul {
    margin: 0;
  }
}

@media (max-width: 767.98px) {
  .container {
    &:not(:first-child) {
      margin-top: 2rem;
    }
    &:nth-child(2n) {
      padding: 2rem 0;
    }
    .title {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }
  }
}