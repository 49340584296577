@import '../../../styles/variables.scss';

.info {
  margin: -3rem 0 -3rem;
  display: grid;
  grid-template-areas: 'Avatar Name Name' 'Avatar Status Version';
  grid-template-columns: auto auto 1fr;
  align-items: center;

  .avatar {
    grid-area: Avatar;
    border-radius: 99999px;
    border: 0.5rem solid white;
    width: 10rem;
    height: 10rem;
    margin-right: 3rem;
  }

  .name {
    grid-area: Name;
    align-self: end;
    text-align: left;
    font-size: 3rem;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-bottom: -1.5rem;
  }

  .status {
    grid-area: Status;
    text-align: left;
    border-radius: 0.5rem;
    background-color: $background-0;
    justify-self: start;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    margin-right: 1rem;
  }

  .version {
    grid-area: Version;
    font-size: 1rem;
    opacity: 0.25;
  }

  &.online {
    .avatar {
      border-color: $background-online;
    }
    .status {
      background-color: $background-online;
    }
  }
}

.commands {
  .list {
    margin-top: 2rem;
  }
}

@media (max-width: 767.98px) {
  .info {
    margin: -2rem 0 -1rem;
    
    .avatar {
      border: 0.25rem solid white;
      width: 5rem;
      height: 5rem;
      margin-right: 1rem;
    }

    .name {
      font-size: 2rem;
      margin-bottom: -0.5rem;
    }
  }
}