@import '../../styles/variables.scss';

@keyframes Animation { 
  0% {opacity: 0.15;}
  50% {opacity: 0.35;}
  100% {opacity: 0.15;}
}

@mixin Animate {
  animation: Animation 3s ease infinite;
}

.container {
  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;

  .logoContainer {
    position: relative;
  }

  .logo {
    max-height: 50vh;
    max-width: 30vw;
  }
  
  .logoBack {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(2rem);
    @include Animate;
  }
}

@media (max-width: 767.98px) {
  .container {
    .logo {
      max-height: 50vh;
      max-width: 60vw;
    }
  }
}