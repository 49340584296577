@import '../../styles/variables.scss';

.container {
  text-align: left;
  padding: 2em 0;

  h1 {
    margin-bottom: 2rem;

    small {
      font-size: 0.75em;
      display: block;
      margin-top: -0.15em;
      margin-bottom: 0.5em;
    }
  }
}

@mixin twoColumn {
  display: grid;
  grid-template-areas: "Content Screenshots" "Buttons Screenshots";
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  align-items: center;
  > * {
    text-align: left;
  }

  .textcontent {
    grid-area: Content;
  }

  .screenshots {
    grid-area: Screenshots;
  }

  .installButton {
    grid-area: Buttons;
  }
}

.twoColumn {
  @include twoColumn;
}

.twoColumnContainer {
  padding: 0;
  background-color: $background-2;
  text-align: left;

  .content {
    @include twoColumn;
    align-items: center;
    padding-top: 5em;
    padding-bottom: 2em;
  
    .subtitle {
      padding-right: 5em;
    }
  }
}

.decoration {
  width: 100%;
  color: $background-2;
  fill: currentColor;

  &.flip {
    transform: scale(-1);
  }
}

.installButton {
  display: inline-grid;
  column-gap: 2rem;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  margin-top: 3rem;
  align-items: start;

  .wrapper {
    display: inline-block;
    justify-self: start;

    .icon {
      margin-right: 1em;
    }

    .version {
      margin-top: 0.5rem;
      display: block;
      font-size: .75rem;
      opacity: 0.5;
      font-weight: bold;
    }
  }
}

.footer {
  background-color: $background-2;
}


@media (max-width: 767.98px) {
  .container {
    overflow: hidden;
  }

  .twoColumnContainer {
    margin-bottom: -1px;

    .content {
      padding-top: 2em;
      grid-template-areas: "Content" "Screenshots" "Buttons";
      grid-template-columns: 1fr;
      column-gap: unset;
    
      .installButton {
        margin: 1rem 0 1rem;
        grid-auto-flow: row;
        grid-auto-columns: 1fr;

        .wrapper {
          display: block;
          justify-self: stretch;
          margin-bottom: 1rem;
          a {
            position: relative;
            display: grid;
          }
        }
      }
      .subtitle {
        padding-right: 0;
      }
    }
  }
  .twoColumn {
    grid-template-areas: "Content" "Screenshots";
    grid-template-columns: 1fr;
    column-gap: unset;
    padding: 0 1rem;

    .textContent {
      grid-area: Content;
    }

    .screenshots {
      grid-area: Screenshots;
    }
  }
}